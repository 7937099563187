import React from "react";

import { graphql } from "gatsby";
import { format, isToday, isYesterday, isThisYear } from "date-fns";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../styles/post.scss";
import "../styles/media.scss";
import "../styles/code.scss";

export default ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const { slug, title, date, description } = pageContext;
  const ruLocale = require("date-fns/locale/ru");

  return (
    <Layout
      pageClass="project"
      title={title}
      link={slug} // Link to this page for using in footer
      linkToProject={post.frontmatter.link} // Link to project page
    >
      <SEO
        title={title}
        description={description}
        image={post.frontmatter.hero.childImageSharp.fluid.src}
      />
      <section className="main__sect--text"><p className="post-links">
        {/* Date of post written */}
        <span
          title={
            format(date, "dddd, D MMMM YYYY", {
              locale: ruLocale
            })
              .charAt(0)
              .toUpperCase() +
            format(date, "dddd, D MMMM YYYY", {
              locale: ruLocale
            }).slice(1)
          }
          className="post-links__span"
        >
          {(() => {
            if (isToday(date)) {
              return "сегодня";
            } else if (isYesterday(date)) {
              return "вчера";
            } else if (isThisYear(date)) {
              return format(date, "D MMMM", {
                locale: ruLocale
              });
            } else {
              return format(date, "D MMMM YYYY", {
                locale: ruLocale
              });
            }
          })()}
        </span>
      </p>
        {/* Content of post converted to HTML */}
        <section dangerouslySetInnerHTML={{ __html: post.html }} />

      </section>
    </Layout>
  );
};

// Getting all information for this post:
// Title
// Date written
// Description
// Title image
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        link
        hero {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;
